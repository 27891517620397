export default [
  {
    width: '40px',
    value: 'edit',
    align: 'center',
    colModifiers:[
      { type: 'edit' }
    ],
    editPath: 'users/edit/'
  },
  { text: 'Имя', value: 'fio' },
  { text: 'Поставщик', value: 'supplier_name' },
  { text: 'Логин', value: 'login' },
  { text: 'Роль', value: 'role' },
  { text: 'Email', value: 'email' }
]
