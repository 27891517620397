<template>
  <div class="rcc-table-list">
    <div class="rcc-table-list__actions-wrapper">
      <rcc-radio-group :options="tableTypeRadioOptions" v-model="tableType" />

      <rcc-button @click="$router.push({ name: 'users-new' })">
        Создать пользователя
      </rcc-button>
    </div>

    <div class="rcc-table-list__table-wrapper">
      <rcc-table
        :page.sync="page"
        :pageCount="pagesCount"
        :itemsPerPage="rowsOnTable"
        :headers="headers"
        :rows="rows"
        :is-fixed-header="true"
        :isLoading="isDataLoading"
        @filters-changed="handleFiltersChanged"
      />
    </div>
  </div>
</template>

<script>
import { objectToOptions } from '@/utils/get-options'
import Page from '@/mixins/page'
import TableList from '@/mixins/table-list'
import UsersApi from '@/api/users'
import headerData from './header'

import RccRadioGroup from 'Components/controls/radio-group/rcc-radio-group.vue'
import RccButton from 'Components/controls/rcc-button'
import RccTable from 'Components/table/rcc-table'

export default {
  mixins: [Page, UsersApi, TableList],

  components: {
    RccTable,
    RccButton,
    RccRadioGroup
  },

  data() {
    return {
      tableType: 'active'
    }
  },

  watch: {
    tableType() {
      this.page = 1
      this.loadData()
    }
  },

  computed: {
    headers() {
      return headerData
    },

    getUsersMethod() {
      return this.tableType === 'active' ? this.getUsers : this.getBlockedUsers
    },

    tableTypeRadioOptions() {
      return objectToOptions(
        { object: this.$constants.status, keyName: 'value', valueName: 'label' }
      )
    }
  },

  methods: {
    loadData(filters) {
      this.isDataLoading = true

      this.getUsersMethod({ page: this.page, per_page: this.rowsOnTable, ...filters })
        .then(({ pages, items }) => {
          if (!pages) {
            this.pagesCount = 1
            this.rows = []
            return
          }

          this.pagesCount = pages
          this.rows = items.map(item => (
            { ...item, role: this.$constants.roles[item.role] })
          )
        })
        .finally(() => { this.isDataLoading = false })
    }
  }
}
</script>

<style lang="scss" scoped>
.rcc-table-list {
  &__actions-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
}
</style>
